import { filterVarTypes } from '../types'
import * as storage from '../../lib/storage'

const initialState = {
    inputFilter: {
        idKota: '',
        idProvinsi: '',
        idDapil: '',
        idPileg: '',
        idTahun: '',
        idPartai: ''
    },
    dataFilter:{
        idKota: '',
        idProvinsi: '',
        idDapil: '',
        idPileg: '',
        idTahun: '',
        idPartai: '',
        namaKota: '',
        namaProvinsi: '',
        namaDapil: '',
        namaPileg: '',
        namaTahun: '',
        namaPartai: '',
    }
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case filterVarTypes.CHANGE_DATA_FILTER: {
            const { field, value } = action.payload
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilter,
                    [field]: value,
                },
                dataFilter: {
                    ...state.dataFilter,
                    [field]: value,
                },
            }
        }
        default:
            return state
    }
}
export default alsusReducer
