import { usulanProgramTypes } from "../types";

const initialState = {
  dataTableUsulanProgram: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    // sort: "",
    // isSortAsc: true,
    // search: "",
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case usulanProgramTypes.GET_DATA_LIST_PROGRAM: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableUsulanProgram: data,
      };
    }
    case usulanProgramTypes.RESET_DATA_LIST_PROGRAM: {
      return {
        ...state,
        dataTableUsulanProgram: {},
      };
    }
    case usulanProgramTypes.CHANGE_DATA_LIST_PROGRAM_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
