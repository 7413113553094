import { userManagementTypes } from '../types'

const initialState = {
    dataTableUser: {},
    inputFilterData: {
        page: 1,
        pageSize: 10,
        sort: '',
        isSortAsc: true,
        search: ''
    },
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case userManagementTypes.GET_DATA_USER: {
            const { data } = action.payload
            return {
                ...state,
                dataTableUser: data,
                inputFilterData: {
                    page: data.page ? data.page : 1,
                    pageSize: data.pageSize ? data.pageSize : 10,
                    sort: '',
                    isSortAsc: true,
                    search: ''
                },
            }
        }
        case userManagementTypes.RESET_DATA_USER: {
            return {
                ...state,
                dataTableUser: {}
            }
        }
        case userManagementTypes.CHANGE_DATA_USER_FILTER: {
            const { field, value } = action.payload
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilterData,
                    [field]: value,
                }
            }
        }
        default:
            return state
    }
}
export default alsusReducer
