import { analisisCalegTypes } from '../types'

const initialState = {
    dataCaleg: {},
    dataDetailCaleg: {},
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case analisisCalegTypes.GET_CAPAIAN_CALEG: {
            const { data } = action.payload
            return {
                ...state,
                dataCaleg: data
            }
        }
        case analisisCalegTypes.GET_DETAIL_CALEG: {
            const { data } = action.payload
            return {
                ...state,
                dataDetailCaleg: data
            }
        }
        case analisisCalegTypes.RESET_DATA: {
            const { data } = action.payload
            return {
                ...state,
                dataCaleg: {},
                dataDetailCaleg: {}
            }
        }
        default:
            return state
    }
}
export default alsusReducer
