import React, { Component } from "react";
import { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import * as storage from "src/lib/storage";
import { Helmet } from "react-helmet";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const PublicPage = React.lazy(() =>
  import("./views/public_pages/index_hmz.js")
);
const Pages = React.lazy(() => import("./views/pages/index_hmz.js"));

class AppHendrik extends Component {
  state = { isAuthenticated: storage.roles() ? true : false };

  login = (e) => {
    this.setState({
      isAuthenticated: e,
    });
  };

  render() {
    return (
      <Fragment>
        {(() => {
          if (!this.state.isAuthenticated) {
            return (
              <>
                <React.Suspense fallback={loading}>
                  {/* untuk akses halaman2 home */}
                  <Route
                    path="/"
                    render={(props) => (
                      <PublicPage {...props} login={this.login} />
                    )}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/login" />}
                  />
                </React.Suspense>
              </>
            );
          } else if (this.state.isAuthenticated) {
            return (
              <>
                <React.Suspense fallback={loading}>
                  {/* untuk akses halaman2 setelah login */}

                  <Route
                    path="/"
                    render={(props) => <Pages {...props} login={this.login} />}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                  />
                </React.Suspense>
              </>
            );
          }
        })()}
        {/* <Route exact path="/" render={() => (
          <Redirect to="/home" />
        )} /> */}
        <Helmet
         title="Kelola Tim Kerja Sobat HMZ"
         meta={[
             { name: "Kelola Tim Kerja Sobat HMZ", content: "Kelola Tim Kerja Sobat HMZ - Aplikasi untuk membantu Anda di Tim Kerja Sobat HMZ untuk memenangkan Hendrik Mahuse dalam Pilkada Merauke 2024." }
         ]}
         link={[
               {"rel": "shortcut icon", 
                "type": "image/png", 
                "href": "logo_kawan.ico"
               }
              ]}
        />
      </Fragment>
    );
  }
}

export default AppHendrik;
