import { masterKanvasingTypes } from "../types";

const initialState = {
  dataTablePeriodeKanvasing: {},
  dataTableProgramKanvasing: {},
  inputFilterPeriode: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
  },
  inputFilterProgram: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case masterKanvasingTypes.GET_DATA_PERIODE_KANVASING: {
      const { data } = action.payload;
      return {
        ...state,
        dataTablePeriodeKanvasing: data,
      };
    }
    case masterKanvasingTypes.CHANGE_DATA_PERIODE_KANVASING_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterPeriode: {
          ...state.inputFilterPeriode,
          [field]: value,
        },
      };
    }
    case masterKanvasingTypes.GET_DATA_PROGRAM_KANVASING: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableProgramKanvasing: data,
      };
    }
    case masterKanvasingTypes.CHANGE_DATA_PROGRAM_KANVASING_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterProgram: {
          ...state.inputFilterProgram,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
