import { hasilAnalisisTypes } from '../types'
import * as storage from '../../lib/storage'

const initialState = {
    dataRincian: {},
    dataRekapitulasi: {},
    dataStatusDapil: {}
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case hasilAnalisisTypes.GET_RINCIAN_DAPIL: {
            const { data } = action.payload
            return {
                ...state,
                dataRincian: data
            }
        }
        case hasilAnalisisTypes.GET_REKAPITULASI_DAPIL: {
            const { data } = action.payload
            return {
                ...state,
                dataRekapitulasi: data
            }
        }
        case hasilAnalisisTypes.GET_STATUS_DAPIL: {
            const { data } = action.payload
            return {
                ...state,
                dataStatusDapil: data
            }
        }
        case hasilAnalisisTypes.RESET_DATA: {
            const { data } = action.payload
            return {
                ...state,
                dataRincian: {},
                dataRekapitulasi: {}
            }
        }
        default:
            return state
    }
}
export default alsusReducer
