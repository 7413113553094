import { pilpresKawalTypes } from "../types";

const initialState = {
  dataTablePilpres: {},
  dataGrafikPilpres: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
    provinsi: "",
    kabKota: "",
    kecamatan: "",
    desa: "",
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case pilpresKawalTypes.GET_DATA_TABLE_PILPRES: {
      const { data } = action.payload;
      return {
        ...state,
        dataTablePilpres: data,
      };
    }
    case pilpresKawalTypes.GET_DATA_GRAFIK_PILPRES: {
      const { data } = action.payload;
      return {
        ...state,
        dataGrafikPilpres: data,
      };
    }
    case pilpresKawalTypes.CHANGE_DATA_TABLE_PILPRES_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
