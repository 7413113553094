import { politicalMappingTypes } from "../types";

const initialState = {
	dataCapaian: {},
	dataPerbandingan: {},
	dataTopThree: {},
	dataPerolehan: {},
	dataRincian: {},
	dataCapianPartai: {},
};

const alsusReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
		case politicalMappingTypes.GET_CAPAIAN: {
			const { capaianPartai, dataCapaian } = action.payload;
			return {
				...state,
				dataCapaian: dataCapaian,
				dataCapaianPartai: capaianPartai,
			};
		}
		case politicalMappingTypes.GET_PERBANDINGAN_SUARA: {
			const { data } = action.payload;
			return {
				...state,
				dataPerbandingan: data,
			};
		}
		case politicalMappingTypes.GET_TOP_THREE: {
			const { data } = action.payload;
			return {
				...state,
				dataTopThree: data,
			};
		}
		case politicalMappingTypes.GET_TABEL_PEROLEHAN: {
			const { data } = action.payload;
			return {
				...state,
				dataPerolehan: data,
			};
		}
		case politicalMappingTypes.GET_TABEL_RINCIAN: {
			const { data } = action.payload;
			return {
				...state,
				dataRincian: data,
			};
		}
		case politicalMappingTypes.RESET_DATA: {
			const { data } = action.payload;
			return {
				...state,
				dataCapaian: {},
				dataPerbandingan: {},
				dataTopThree: {},
				dataPerolehan: {},
				dataRincian: {},
			};
		}
		default:
			return state;
	}
};
export default alsusReducer;
