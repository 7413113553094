import { capaianKunjunganTypes } from "../types";

const initialState = {
  dataTableCapaianKunjungan: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
    dto: {
      idProvinsi: "",
      idKota:"",
      idKecamatan:"",
      idDesa:"",
    }
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case capaianKunjunganTypes.GET_DATA_CAPAIAN_KUNJUNGAN: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableCapaianKunjungan: data,
      };
    }
    case capaianKunjunganTypes.RESET_DATA_CAPAIAN_KUNJUNGAN: {
      return {
        ...state,
        dataTableCapaianKunjungan: {},
      };
    }
    case capaianKunjunganTypes.CHANGE_DATA_CAPAIAN_KUNJUNGAN_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
