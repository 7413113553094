import { storageFactory } from 'storage-factory';
import jwt from 'jwt-decode'
import axios from 'axios'
import { SERVICE } from './config'

export const local = storageFactory(() => localStorage);
export const session = storageFactory(() => sessionStorage);


export const userInfo = () => {
    return local.getItem('accessTokenResponse') ? jwt(local.getItem('accessTokenResponse')) : ''
};

export const roles = () => {
    let infouser = userInfo()
    return infouser ? infouser.realm_access.roles : ''
};

export const access = () => {
    let infouser = userInfo()
    return infouser ? infouser.realm_access.roles.toString() : ''
};

export const accessWeSurvey = () => {
    let infouser = userInfo()
    return infouser ? infouser.resource_access['wesurvey-client']?.roles : ''
};

export const getWilayah = async () => {
    let wilayah = {}
    await axios.get(SERVICE.JAVA_SERVICE + "/user/wilayah")
        .then((response) => {
            wilayah = response.data.data
        });
    return wilayah
}

// export const getWilayah = async () => {
//     let wilayah = (await axios.get(SERVICE.JAVA_SERVICE + '/user/wilayah')).data.data
//     return wilayah 
// }

// export const userInfo = local.getItem('accessTokenResponse') ? jwt(local.getItem('accessTokenResponse').access_token) : ''
// export const roles = userInfo ? userInfo.realm_access.roles.toString() : ''
// export const access = userInfo ? userInfo.resource_access['sdgs-client'].roles.toString() : ''

//get user name
// const wilayah = response.data.data.wilayah
// storage.local.setItem('wilayah', wilayah)
// storage.local.setItem('provinsi', response.data.data.kodeProvinsi ? response.data.data.kodeProvinsi : '')
// storage.local.setItem('kota', response.data.data.kodeKota ? response.data.data.kodeKota : '')
// storage.local.setItem('kecamatan', response.data.data.kodeKecamatan ? response.data.data.kodeKecamatan : '')
// storage.local.setItem('desa', response.data.data.kodeDesa ? response.data.data.kodeDesa : '')