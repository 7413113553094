import React, { Component } from "react";
import { Fragment } from "react";
import { Redirect, Route } from "react-router-dom";
import * as storage from "src/lib/storage";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/OuterLayout'))

// Pages
// const Login = React.lazy(() => import('./views/public_pages/login/Login'))
// const Register = React.lazy(() => import('./views/public_pages/register/Register'))
// const Page404 = React.lazy(() => import('./views/public_pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/public_pages/page500/Page500'))

const PublicPage = React.lazy(() => import("./views/public_pages"));
const Pages = React.lazy(() => import("./views/pages"));

class App extends Component {
  state = { isAuthenticated: storage.roles() ? true : false };

  login = (e) => {
    this.setState({
      isAuthenticated: e,
    });
  };

  render() {
    return (
      <Fragment>
        {(() => {
          if (!this.state.isAuthenticated) {
            return (
              <>
                <React.Suspense fallback={loading}>
                  {/* untuk akses halaman2 home */}
                  <Route
                    path="/"
                    render={(props) => (
                      <PublicPage {...props} login={this.login} />
                    )}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                  />
                </React.Suspense>
              </>
            );
          } else if (this.state.isAuthenticated) {
            return (
              <>
                <React.Suspense fallback={loading}>
                  {/* untuk akses halaman2 setelah login */}

                  <Route
                    path="/"
                    render={(props) => <Pages {...props} login={this.login} />}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/home" />}
                  />
                </React.Suspense>
              </>
            );
          }
        })()}
        {/* <Route exact path="/" render={() => (
          <Redirect to="/home" />
        )} /> */}
      </Fragment>
    );
  }
}

export default App;
