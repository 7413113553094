import { perbandinganSuaraTypes } from '../types'

const initialState = {
    dataCaleg: {
        data: {},
        capaian: {}
    },
    dataDetailCaleg: {},
    dataCalegBanding: {
        data: {},
        capaian: {}
    },
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case perbandinganSuaraTypes.GET_CAPAIAN_CALEG: {
            const { data, dataSuara } = action.payload
            return {
                ...state,
                dataCaleg: {
                    ...state.dataCaleg,
                    data: data,
                    capaian: dataSuara
                }
            }
        }
        case perbandinganSuaraTypes.GET_CAPAIAN_CALEG_BANDING: {
            const { data, dataSuara } = action.payload
            return {
                ...state,
                dataCalegBanding: {
                    ...state.dataCalegBanding,
                    data: data,
                    capaian: dataSuara
                }
            }
        }
        case perbandinganSuaraTypes.GET_DETAIL_CALEG: {
            const { data } = action.payload
            return {
                ...state,
                dataDetailCaleg: data
            }
        }
        case perbandinganSuaraTypes.RESET_DATA: {
            return {
                ...state,
                dataCaleg: {
                    data: {},
                    capaian: {}
                },
                dataDetailCaleg: {},
                dataCalegBanding: {
                    data: {},
                    capaian: {}
                }
            }
        }
        case perbandinganSuaraTypes.RESET_DATA_DETAIL: {
            return {
                ...state,
                dataDetailCaleg: {}
            }
        }
        default:
            return state
    }
}
export default alsusReducer
