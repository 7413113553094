import { usulanRoadshowTypes } from "../types";

const initialState = {
  dataTableUsulanRoadshow: {},
  dataCalendarUsulanRoadshow: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    // sort: "",
    // isSortAsc: true,
    // search: "",
    dto: {
      idProvinsi: "",
      idKota: "",
      idKecamatan: "",
      idDesa: "",
    },
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case usulanRoadshowTypes.GET_DATA_LIST_ROADSHOW: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableUsulanRoadshow: data,
      };
    }
    case usulanRoadshowTypes.GET_DATA_CALENDAR_ROADSHOW: {
      const { data } = action.payload;
      return {
        ...state,
        dataCalendarUsulanRoadshow: data,
      };
    }
    case usulanRoadshowTypes.RESET_DATA_LIST_ROADSHOW: {
      return {
        ...state,
        dataTableUsulanRoadshow: {},
      };
    }
    case usulanRoadshowTypes.CHANGE_DATA_LIST_ROADSHOW_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
