import { saksiTypes } from '../types'
import * as storage from '../../lib/storage'

const initialState = {
    dataTableSaksi: {},
    dataCapaianSaksi: {},
    inputFilterData: {
        page: 1,
        pageSize: 10,
        sort: '',
        isSortAsc: true,
        search: '',
        idProvinsi: storage.userInfo()['kawal-prov'] !== "null" ? storage.userInfo()['kawal-prov'] : '',
        idKota: storage.userInfo()['kawal-kota'] !== "null" ? storage.userInfo()['kawal-kota'] : '',
        idKecamatan: storage.userInfo()['kawal-kecamatan'] !== "null" ? storage.userInfo()['kawal-kecamatan'] : '',
        idDesa: storage.userInfo()['kawal-desa'] !== "null" ? storage.userInfo()['kawal-desa'] : '',
        totalItems: 0,
    },
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case saksiTypes.GET_DATA_SAKSI: {
            const { data } = action.payload
            return {
                ...state,
                dataTableSaksi: data.data,
                inputFilterData: {
                    page: data.page ? data.page : 1,
                    pageSize: data.pageSize ? data.pageSize : 10,
                    totalItems: data.totalItems ? data.totalItems : 0,
                    sort: '',
                    isSortAsc: true,
                    search: '',
                    idProvinsi: data.data.idProvinsi ? data.data.idProvinsi : '',
                    idKota: data.data.idKota ? data.data.idKota : '',
                    idKecamatan: data.data.idKecamatan ? data.data.idKecamatan : '',
                    idDesa: data.data.idDesa ? data.data.idDesa : '',
                },
            }
        }
        case saksiTypes.GET_CAPAIAN_SAKSI: {
            const { data } = action.payload
            return {
                ...state,
                dataCapaianSaksi: data.data
            }
        }
        case saksiTypes.RESET_DATA_SAKSI: {
            return {
                ...state,
                dataTableSaksi: {},
                inputFilterData: initialState.inputFilterData
            }
        }
        case saksiTypes.CHANGE_DATA_SAKSI_FILTER: {
            const { field, value } = action.payload
            return {
                ...state,
                inputFilter: {
                    ...state.inputFilterData,
                    [field]: value,
                }
            }
        }
        default:
            return state
    }
}
export default alsusReducer
