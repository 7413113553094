import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AppHmz from "./AppHmz";
import { CONFIG } from "./lib/config";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./redux/configureStore";
import "./lib/auth";
import AppQc from "./AppQc";
const root = createRoot(document.getElementById("root"));
const store = configureStore();
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {CONFIG.APP === "HMZ" ? <AppHmz /> : CONFIG.APP === "QC" ? <AppQc /> : <App />}
    </BrowserRouter>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
