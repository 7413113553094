import { combineReducers } from "redux";

import sidebarReducer from "./sidebar";
import filterVarReducer from "./filterVar";
import dashboardReducer from "./dashboard";
import politicalMappingReducer from "./politicalMapping";
import hasilAnalisisReducer from "./hasilAnalisis";
import analisisCalegReducer from "./analisisCaleg";
import perbandinganSuaraReducer from "./perbandinganSuara";
import userManagementReducer from "./userManagement";
import saksiReducer from "./saksi";
import userReducer from "./user";
import projectReducer from "./project";
import capaianWilayahReducer from "./capaianWilayah";
import capaianRekrutRelawanReducer from "./capaianRekrutRelawan";
import capaianKanvasingReducer from "./capaianKanvasing";
import capaianKinerjaReducer from "./capaianKinerja";
import pemasanganApkReducer from "./pemasanganApk";
import capaianKunjunganReducer from "./capaianKunjungan";
import listDptReducer from "./listDpt";
import listKanvasingReducer from "./listKanvasing";
import formsReducer from "./forms";
import pilpresKawalReducer from "./pilpresKawal";
import dprriKawalReducer from "./dprriKawal";
import dprdprovKawalReducer from "./dprdprovKawal";
import dprdkabKawalReducer from "./dprdkabKawal";
import masterKanvasingReducer from "./masterKanvasing";
import usulanRoadshowReducer from "./usulanRoadshow";
import usulanProgramReducer from "./usulanProgram";

const rootReducer = combineReducers({
  sidebarReducer,
  filterVarReducer,
  dashboardReducer,
  politicalMappingReducer,
  hasilAnalisisReducer,
  analisisCalegReducer,
  perbandinganSuaraReducer,
  userManagementReducer,
  saksiReducer,
  userReducer,
  projectReducer,
  capaianWilayahReducer,
  capaianRekrutRelawanReducer,
  capaianKanvasingReducer,
  capaianKinerjaReducer,
  pemasanganApkReducer,
  capaianKunjunganReducer,
  listDptReducer,
  listKanvasingReducer,
  formsReducer,
  pilpresKawalReducer,
  dprriKawalReducer,
  dprdprovKawalReducer,
  dprdkabKawalReducer,
  masterKanvasingReducer,
  usulanRoadshowReducer,
  usulanProgramReducer,
});
export default rootReducer;
