import { projectTypes } from "../types";

const initialState = {
  dataTableProject: {},
  dataTableTipeUser: {},
  dataUserProject: {},
  dataInitProject: "",
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
  },
  inputFilterDataTipeUser: {
    idMasterProject: "",
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case projectTypes.GET_DATA_PROJECT: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableProject: data,
      };
    }
    case projectTypes.GET_DETAIL_PROJECT: {
      const { data } = action.payload;
      return {
        ...state,
        dataInitProject: data,
      };
    }
    case projectTypes.RESET_DETAIL_PROJECT: {
      return {
        ...state,
        dataInitProject: "",
      };
    }
    case projectTypes.GET_DROPDOWN_USER: {
      const { data } = action.payload;
      return {
        ...state,
        dataUserProject: data,
      };
    }
    case projectTypes.RESET_DATA_PROJECT: {
      return {
        ...state,
        dataTableProject: {},
      };
    }
    case projectTypes.CHANGE_DATA_PROJECT_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    case projectTypes.GET_TIPE_USER: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableTipeUser: data,
      };
    }
    case projectTypes.RESET_DATA_TIPE_USER: {
      return {
        ...state,
        dataTableTipeUser: {},
      };
    }
    case projectTypes.CHANGE_DATA_TIPE_USER_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterDataTipeUser: {
          ...state.inputFilterDataTipeUser,
          [field]: value,
        },
      };
    }
    case projectTypes.CHANGE_DATA_WILAYAH_PROJECT: {
      const { field, value } = action.payload;
      return {
        ...state,
        dataInitProject: {
          ...state.dataInitProject,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
