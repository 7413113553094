import { formsTypes } from "../types";

const initialState = {
  dataTableForms: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case formsTypes.GET_DATA_FORMS: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableForms: data,
      };
    }
    case formsTypes.RESET_DATA_FORMS: {
      return {
        ...state,
        dataTableForms: {},
      };
    }
    case formsTypes.CHANGE_DATA_FORMS_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
