// {
//     "App": {
//       "NAME": "FMS",
//       "VERSION": "cache-control",
//       "DEVELOPMENT": true
//     },
//     "SERVICE": {
//       "JAVA_SERVICE": process.env.END_POINT
//     }
//   }

export const SERVICE = {
  JAVA_SERVICE: process.env.REACT_APP_END_POINT,
  JAVA_SERVICE_NEW: process.env.REACT_APP_END_POINT_NEW,
  SAKSI_SERVICE: process.env.REACT_APP_END_POINT_SAKSI,
  WILAYAH_SERVICE: process.env.REACT_APP_END_POINT_WILAYAH,
  USER_SERVICE: process.env.REACT_APP_END_POINT_USER,
  KAWAN_SERVICE: process.env.REACT_APP_END_POINT_KAWAN,
  WESURVEY_SERVICE: process.env.REACT_APP_WESURVEY_ENDPOINT_KAWAL,
};

const host = location.protocol.concat("//").concat(window.location.host);
const apptype = host.includes("hendrikmahuse.com") ? "HMZ" : host.includes("kawal.kampanye2024.com") ? "QC" : ""
export const CONFIG = {
  APP: apptype
};
