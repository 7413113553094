import { listKanvasingTypes } from "../types";

const initialState = {
  dataTableListKanvasing: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
    dto: {
      idProvinsi: "",
      idKota:"",
      idKecamatan:"",
      idDesa:"",
    }
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case listKanvasingTypes.GET_DATA_LIST_KANVASING: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableListKanvasing: data,
      };
    }
    case listKanvasingTypes.RESET_DATA_LIST_KANVASING: {
      return {
        ...state,
        dataTableListKanvasing: {},
      };
    }
    case listKanvasingTypes.CHANGE_DATA_LIST_KANVASING_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
