import axios from "axios";
import Swal from "sweetalert2";
// import * as Survey from 'survey-react'
import * as storage from "./storage";

axios.interceptors.response.use(
  (response) => {
    if (!(response.data.status >= 200 && response.data.status < 300)) {
      Swal.fire({
        icon: "error",
        title: response.data.message,
      });
    } else if (response.data.status === 401 || response.data.status === 404) {
      Swal.fire({
        icon: "error",
        title: "Waktu login habis",
      });
      storage.local.clear();
      window.location.href = "/";
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      Swal.fire({
        icon: "error",
        title: "Waktu login habis",
      });
      storage.local.clear();
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } else {
      Swal.fire({
        icon: "error",
        title:
          error.response.statusText && error.response.statusText != ""
            ? error.response.statusText
            : error,
      });
    }

    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (request) => {
    const accessTokenResponse = JSON.parse(
      storage.local.getItem("accessTokenResponse")
    );

    if (accessTokenResponse === "null" || !accessTokenResponse) {
      return request;
    } else {
      const accessToken = accessTokenResponse["access_token"];
      request.headers = {
        Authorization: `Bearer ${accessToken}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      return request;
    }
  },
  (error) => {
    Swal.fire({
      icon: "error",
      title: "Koneksi Jaringan Terputus",
    });
    return Promise.reject(error);
  }
);

// Survey.ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
//     const accessTokenResponse = JSON.parse(storage.local.getItem('accessTokenResponse'))
//     const accessToken = accessTokenResponse["access_token"]
//     options.request.setRequestHeader("Authorization", "Bearer " + accessToken);
// }
