import { dashboardTypes } from '../types'
import * as storage from '../../lib/storage'

const initialState = {
    dataCapaian: {},
    dataPerbandingan: {},
    dataTopThree: {},
    dataCapaianPartai: {}
}

const alsusReducer = (state = { ...initialState }, action) => {
    switch (action.type) {
        case dashboardTypes.GET_CAPAIAN_DPRD: {
            const { data } = action.payload
            return {
                ...state,
                dataCapaian: data
            }
        }
        case dashboardTypes.GET_PERBANDINGAN_SUARA: {
            const { data } = action.payload
            return {
                ...state,
                dataPerbandingan: data
            }
        }
        case dashboardTypes.GET_TOP_THREE: {
            const { data } = action.payload
            return {
                ...state,
                dataTopThree: data
            }
        }
        case dashboardTypes.GET_CAPAIAN_PARTAI: {
            const { data } = action.payload
            return {
                ...state,
                dataCapaianPartai: data
            }
        }
        case dashboardTypes.RESET_DATA: {
            const { data } = action.payload
            return {
                ...state,
                dataCapaian: {},
                dataPerbandingan: {},
                dataTopThree: {},
                dataCapaianPartai: {},
                dataStatusDapil: {}
            }
        }
        default:
            return state
    }
}
export default alsusReducer
