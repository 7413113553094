const projectTypes = {
  GET_DATA_PROJECT: "GET DATA PROJECT",
  GET_DETAIL_PROJECT: "GET DETAIL PROJECT",
  RESET_DETAIL_PROJECT: "RESET DETAIL PROJECT",
  GET_DROPDOWN_USER: "GET DROPDOWN USER PROJECT OWNER",
  CHANGE_DATA_PROJECT_FILTER: "CHANGE DATA PROJECT FILTER",
  RESET_DATA_PROJECT: "RESET DATA MANAGEMENT PROJECT",
  GET_TIPE_USER: "GET TIPE USER",
  CHANGE_DATA_TIPE_USER_FILTER: "CHANGE DATA TIPE USER FILTER",
  RESET_DATA_TIPE_USER: "RESET DATA TIPE USER",
  CHANGE_DATA_WILAYAH_PROJECT: "CHANGE DATA WILAYAH PROJECT"
}
export default projectTypes;
