import { capaianKanvasingTypes } from "../types";

const initialState = {
  dataTableCapaianKanvasing: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
    idProvinsi: "",
    idKota:"",
    idKecamatan:"",
    idDesa:"",
    idClient:""
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case capaianKanvasingTypes.GET_DATA_KANVASING: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableCapaianKanvasing: data,
      };
    }
    case capaianKanvasingTypes.RESET_DATA_KANVASING: {
      return {
        ...state,
        dataTableCapaianKanvasing: {},
      };
    }
    case capaianKanvasingTypes.CHANGE_DATA_KANVASING_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
