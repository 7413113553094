import { capaianWilayahTypes } from "../types";

const initialState = {
  dataTableCapaianWilayah: {},
  dataGrafikWilayah: {},
  inputFilterData: {
    page: 1,
    pageSize: 10,
    sort: "",
    isSortAsc: true,
    search: "",
    dto: {
      idProvinsi: "",
      idKota:"",
      idKecamatan:"",
      idDesa:"",
    }
  },
};

const alsusReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case capaianWilayahTypes.GET_DATA_CAPAIAN_WILAYAH: {
      const { data } = action.payload;
      return {
        ...state,
        dataTableCapaianWilayah: data,
      };
    }
    case capaianWilayahTypes.GET_DATA_GRAFIK_WILAYAH: {
      const { data } = action.payload;
      return {
        ...state,
        dataGrafikWilayah: data,
      };
    }
    case capaianWilayahTypes.RESET_DATA_CAPAIAN_WILAYAH: {
      return {
        ...state,
        dataTableCapaianWilayah: {},
      };
    }
    case capaianWilayahTypes.CHANGE_DATA_CAPAIAN_WILAYAH_FILTER: {
      const { field, value } = action.payload;
      return {
        ...state,
        inputFilterData: {
          ...state.inputFilterData,
          [field]: value,
        },
      };
    }
    default:
      return state;
  }
};
export default alsusReducer;
